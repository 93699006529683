html {
  background-color: #E9E9EE;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  /* font-family:  "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f5f5f7; */
  background-color: #E9E9EE;
  color: #262626;
  height: 100vh;
  /* padding-top: 50px; */
}
a {
  color: #333333;
}

a:hover{
  color: #666666;
  text-decoration: none;
}

a:link {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
